<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card class="m-0 p-0">
      <b-row class="mb-25">
        <b-col cols="12" md="6" class="mb-1 mb-md-0">
          <div class="d-flex align-items-center">
            <span class="mr-2 font-weight-bold">Today's Date:</span>
            <span>{{ dateNow }}</span>
          </div>
          <div class="d-flex align-items-center">
            <span class="mr-2 font-weight-bold">Display Week:</span>
            <span>{{ getWeekDate }}</span>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-md-0"
        >

        <b-button
            :style="{ visibility: !hideMode ? 'visible' : 'hidden' }"
            v-show="$Can('rota_builder_button')"
            variant="primary"
            @click="rotaBuilder"
            class="mr-1"
          >
            Rota Builder
          </b-button> 

          <b-button
            :style="{ visibility: !hideMode ? 'visible' : 'hidden' }"
            v-show="$Can('download_pdf_button')"
            variant="primary"
            @click="downloadPDF"
            class="mr-1"
          >
            Download PDF
          </b-button>

          <b-button
            :style="{ visibility: !hideMode ? 'visible' : 'hidden' }"
            v-show="$Can('create_routine_button')"
            variant="primary"
            @click="routine"
            class="mr-1"
          >
            Create Routine
          </b-button>

          <b-button
            v-if="!hideMode"
            v-show="$Can('duplicate_week_button')"
            variant="primary"
            @click="duplicate"
            class=""
          >
            Duplicate Week
          </b-button>

          <b-button
            v-if="hideMode"
            :variant="showHidden ? 'primary' : 'secondary'"
            @click="
              showHidden = !showHidden;
              selectWeek(selectedWeek);
            "
            class="mr-1"
          >
            {{ showHidden ? "Show Active" : "Show Hidden" }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <div class="container text-center mt-1 d-flex justify-content-center">
      <treeselect
        class="mr-25"
        v-model="selectedYear"
        :multiple="false"
        :options="availableYears"
        @input="selectYear(selectedYear)"
        :clearable="false"
        style="width: 100px;"
      >
        <template #option="{ node }">
          {{ node.label }}
        </template>
      </treeselect>

      <treeselect
        class="mr-25"
        v-model="selectedWeek"
        :multiple="false"
        :options="dropdownWeeks"
        @input="selectWeek(selectedWeek)"
        :clearable="false"
        style="width: 250px;"
      >
        <div slot="value-label" slot-scope="{ node }">
          {{ node.raw.customLabel }}
        </div>
      </treeselect>

      <b-button @click="moveWeeks(-3)" variant="dark" class="mr-25"
        >&lt;&lt; Back</b-button
      >
      <b-button
        v-for="(week, index) in displayedWeeks"
        :key="index"
        style="width: 5%; height: 37px; font-size: 14px;"
        :variant="week.week_number === selectedWeek ? 'success' : 'secondary'"
        class="p-50 m-0 mr-25 flex-fill"
        @click="selectedWeek = week.week_number"
      >
        {{ week.week_number }}
      </b-button>
      <b-button @click="moveWeeks(+3)" variant="dark">Next &gt;&gt;</b-button>

      <b-checkbox
        v-show="$Can('hide_user_schedule')"
        switch
        v-model="hideMode"
        @change="checkShowHidden"
        class="ml-1 align-self-end"
      >
        Hide Mode
      </b-checkbox>
    </div>

    <span v-if="weeks.length > 0">
      <!-- Filtrelenen Değerlerin Gösterildiği Alan -->

      <div
        class="bg-white m-0 pl-1 pr-1 pt-50 pb-0 mb-0 mt-1"
        v-if="filterApplied"
      >
        <b-row class="mb-1">
          <b-col cols="12">
            <div class="d-flex align-items-center">
              <span class="mr-2 font-weight-bold">Selected Filters:</span>
              <div class="d-flex flex-wrap">
                <div
                  v-for="(department, index) in selectedDepartments"
                  :key="'department-' + index"
                  class="filter-badge"
                >
                  <span>{{ getDepartmentTitle(department) }}</span>
                  <b-button
                    @click="removeDepartment(department)"
                    class="close-button"
                    >x</b-button
                  >
                </div>
                <div
                  v-for="(site, index) in selectedSites"
                  :key="'site-' + index"
                  class="filter-badge"
                >
                  <span>{{ getSiteTitle(site) }}</span>
                  <b-button @click="removeSite(site)" class="close-button"
                    >x</b-button
                  >
                </div>
                <div
                  v-for="(role, index) in selectedRoles"
                  :key="'role-' + index"
                  class="filter-badge"
                >
                  <span>{{ getRoleName(role) }}</span>
                  <b-button @click="removeRole(role)" class="close-button"
                    >x</b-button
                  >
                </div>

                <div
                  v-for="(staff, index) in selectedStaffs"
                  :key="'staff-' + index"
                  class="filter-badge"
                >
                  <span>{{ getStaffName(staff) }}</span>
                  <b-button @click="removeStaff(staff)" class="close-button"
                    >x</b-button
                  >
                </div>
                <div
                  v-for="(task, index) in selectedDayTasks"
                  :key="'task-' + index"
                  class="filter-badge"
                >
                  <span>{{ getTaskWithDay(task) }}</span>
                  <b-button @click="removeTask(task)" class="close-button"
                    >x</b-button
                  >
                </div>

             

                <div
                  v-for="(time, index) in selectedTimes"
                  :key="'time-' + index"
                  class="filter-badge"
                >
                  <span>{{ getTimeLabel(time) }}</span>
                  <b-button @click="removeTime(time)" class="close-button"
                    >x</b-button
                  >
                </div>
              </div>
              <b-button
                variant="outline-danger"
                size="sm"
                class="ml-1 mb-25"
                @click="clearAllFilters"
                >Clear</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="mt-1 weekTableCard" :style="'height:' + windowHeight + 'px'">
        <table
          class="table table-bordered table-sm"
          data-filter-control="true"
          data-show-search-clear-button="true"
        >
          <thead style="position: sticky; top: 0;  z-index: 1;">
            <tr class="text-center">
              <th class="m-0 p-0" style="width: 100px;" v-if="hideMode">
                Hidden
              </th>
              <th class="m-0 p-0" style="width: 100px;">
                <div style="position: relative;">
                  <button
                    style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                    id="department-filter-button"
                  >
                    Dep.
                    <i
                      class="fa fa-chevron-down"
                      style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                    ></i>
                  </button>
                  <b-popover
                    ref="departmentFilterPopover"
                    triggers="click blur"
                    placement="bottom"
                    :target="'department-filter-button'"
                    style="border: none; box-shadow: none;"
                  >
                    <div
                      class="p-50"
                      style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                    >
                      <input
                        type="text"
                        v-model="departmentFilter"
                        placeholder="Search"
                        style="padding: 5px; width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;"
                      />
                      <label
                        v-if="filteredDepartments.length > 0"
                        class="checkbox mt-1"
                        style="margin-left: 10px; font-weight: bold;"
                      >
                        <input
                          type="checkbox"
                          @change="selectAllDepartments"
                          v-model="chexboxAllDepartment"
                          style="margin-right: 5px;"
                        />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul
                        v-if="filteredDepartments.length > 0"
                        style="list-style: none; padding-left: 0;"
                      >
                        <li
                          v-for="(department, index) in filteredDepartments"
                          :key="index"
                          style="margin-bottom: 5px;"
                        >
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              :value="department.id"
                              v-model="selectedDepartments"
                              style="margin-right: 5px;"
                            />
                            {{ department.title }}
                          </label>
                        </li>
                      </ul>
                      <span v-else style="margin-left: 10px;"
                        >No departments found.</span
                      >
                    </div>
                    <button
                      @click="applyFilter('department')"
                      class="btn btn-primary"
                      style="width: 100%; margin-top: 10px;"
                    >
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>

              <th class="m-0 p-0" style="width: 100px;">
                <div style="position: relative;">
                  <button
                    style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent; padding-right: 30px;"
                    id="site-filter-button"
                  >
                    Site
                    <i
                      class="fa fa-chevron-down"
                      style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                    ></i>
                  </button>
                  <b-popover
                    ref="siteFilterPopover"
                    triggers="click blur"
                    placement="bottom"
                    :target="'site-filter-button'"
                    style="border: none; box-shadow: none;"
                  >
                    <div
                      class="p-50"
                      style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                    >
                      <input
                        type="text"
                        v-model="siteFilter"
                        placeholder="Search"
                        style="padding: 5px; width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;"
                      />
                      <label
                        v-if="filteredSites.length > 0"
                        class="checkbox mt-1"
                        style="margin-left: 10px; font-weight: bold;"
                      >
                        <input
                          type="checkbox"
                          @change="selectAllSites"
                          v-model="chexboxAllSite"
                          style="margin-right: 5px;"
                        />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul
                        v-if="filteredSites.length > 0"
                        style="list-style: none; padding-left: 0;"
                      >
                        <li
                          v-for="(site, index) in filteredSites"
                          :key="index"
                          style="margin-bottom: 5px;"
                        >
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              :value="site.id"
                              v-model="selectedSites"
                              style="margin-right: 5px;"
                            />
                            {{ site.title }}
                          </label>
                        </li>
                      </ul>
                      <span v-else style="margin-left: 10px;"
                        >No sites found.</span
                      >
                    </div>
                    <button
                      @click="applyFilter('site')"
                      class="btn btn-primary"
                      style="width: 100%; margin-top: 10px;"
                    >
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>

              <th class="m-0 p-0" style="width: 90px;">
                <div style="position: relative;">
                  <button
                    style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                    id="role-filter-button"
                  >
                    Role
                    <i
                      class="fa fa-chevron-down"
                      style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                    ></i>
                  </button>
                  <b-popover
                    ref="roleFilterPopover"
                    triggers="click blur"
                    placement="bottom"
                    :target="'role-filter-button'"
                    style="border: none; box-shadow: none;"
                  >
                    <div
                      class="p-50"
                      style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                    >
                      <input
                        type="text"
                        v-model="roleFilter"
                        placeholder="Search"
                        style="padding: 5px; width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;"
                      />
                      <label
                        v-if="filteredRoles.length > 0"
                        class="checkbox mt-1"
                        style="margin-left: 10px; font-weight: bold;"
                      >
                        <input
                          type="checkbox"
                          @change="selectAllRoles"
                          v-model="chexboxAllRole"
                          style="margin-right: 5px;"
                        />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul
                        v-if="filteredRoles.length > 0"
                        style="list-style: none; padding-left: 0;"
                      >
                        <li
                          v-for="(role, index) in filteredRoles"
                          :key="index"
                          style="margin-bottom: 5px;"
                        >
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              :value="role.id"
                              v-model="selectedRoles"
                              style="margin-right: 5px;"
                            />
                            {{ role.name }}
                          </label>
                        </li>
                      </ul>
                      <span v-else style="margin-left: 10px;"
                        >No role found.</span
                      >
                    </div>
                    <button
                      @click="applyFilter('role')"
                      class="btn btn-primary"
                      style="width: 100%; margin-top: 10px;"
                    >
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>

              <th class="m-0 p-0" style="width: 200px;">
                <div style="position: relative;">
                  <button
                    style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                    id="staff-filter-button"
                  >
                    Staff
                    <i
                      class="fa fa-chevron-down"
                      style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                    ></i>
                  </button>
                  <b-popover
                    ref="staffFilterPopover"
                    triggers="click blur"
                    placement="bottom"
                    :target="'staff-filter-button'"
                    style="border: none; box-shadow: none;"
                  >
                    <div
                      class="p-50"
                      style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                    >
                      <input
                        type="text"
                        v-model="staffFilter"
                        placeholder="Search"
                        style="padding: 5px; width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;"
                      />
                      <label
                        v-if="filteredStaffs.length > 0"
                        class="checkbox mt-1"
                        style="margin-left: 10px; font-weight: bold;"
                      >
                        <input
                          type="checkbox"
                          @change="selectAllStaffs"
                          v-model="chexboxAllStaff"
                          style="margin-right: 5px;"
                        />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul
                        v-if="filteredStaffs.length > 0"
                        style="list-style: none; padding-left: 0;"
                      >
                        <li
                          v-for="(staff, index) in filteredStaffs"
                          :key="index"
                          style="margin-bottom: 5px;"
                        >
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              :value="staff.id"
                              v-model="selectedStaffs"
                              style="margin-right: 5px;"
                            />
                            {{ staff.name }} {{ staff.surname }}
                          </label>
                        </li>
                      </ul>
                      <span v-else style="margin-left: 10px;"
                        >No staff found.</span
                      >
                    </div>
                    <button
                      @click="applyFilter('staff')"
                      class="btn btn-primary"
                      style="width: 100%; margin-top: 10px;"
                    >
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>

            

              <th class="m-0 p-0" style="width: 90px;">
                <div style="position: relative;">
                  <button
                    style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                    id="time-filter-button"
                  >
                    Time
                    <i
                      class="fa fa-chevron-down"
                      style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                    ></i>
                  </button>
                  <b-popover
                    ref="timeFilterPopover"
                    triggers="click blur"
                    placement="bottom"
                    :target="'time-filter-button'"
                    style="border: none; box-shadow: none;"
                  >
                    <div
                      class="p-50"
                      style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                    >
                      <label
                        class="checkbox mt-1"
                        style="margin-left: 10px; font-weight: bold;"
                      >
                        <input
                          type="checkbox"
                          @change="selectAllTimes"
                          v-model="checkboxAllTime"
                          style="margin-right: 5px;"
                        />
                        Select All
                      </label>
                      <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                      <ul style="list-style: none; padding-left: 0;">
                        <li>
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              value="AM"
                              v-model="selectedTimes"
                              style="margin-right: 5px;"
                            />
                            AM
                          </label>
                        </li>
                        <li>
                          <label class="checkbox" style="margin-left: 10px;">
                            <input
                              type="checkbox"
                              value="PM"
                              v-model="selectedTimes"
                              style="margin-right: 5px;"
                            />
                            PM
                          </label>
                        </li>
                      </ul>
                    </div>
                    <button
                      @click="applyFilter('time')"
                      class="btn btn-primary"
                      style="width: 100%; margin-top: 10px;"
                    >
                      Apply Filter
                    </button>
                  </b-popover>
                </div>
              </th>

              <th
                v-for="(day, index) in days"
                :key="index"
                class="m-0 p-0"
                style="position: relative; min-width: 125px;"
              >
                <button
                  style="color: white; border: none; padding: 5px; cursor: pointer; width:100%; height: 50px;  background-color:transparent"
                  :id="day + '-filter-button'"
                >
                  {{ day.charAt(0).toUpperCase() + day.slice(1) }} <br /><small
                    >{{ weeksDates[index] }}</small
                  >
                  <i
                    class="fa fa-chevron-down"
                    style="position: absolute; right: 7px; top: 50%; transform: translateY(-50%);"
                  ></i>
                </button>
                <b-popover
                  :ref="day + '_FilterPopover'"
                  triggers="click blur"
                  placement="bottom"
                  :target="day + '-filter-button'"
                  style="border: none; box-shadow: none;"
                >
                  <div
                    class="p-50"
                    style="max-height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 5px; background-color: white;"
                  >
                    <input
                      type="text"
                      v-model="dayTaskFilters"
                      placeholder="Search"
                      style="padding: 5px;
                    width: 100%; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 5px;"
                    />
                    <label
                      v-if="filteredTasks.length > 0"
                      class="checkbox mt-1"
                      style="margin-left: 10px; font-weight: bold;"
                    >
                      <input
                        type="checkbox"
                        @change="selectAllDayTasks(day)"
                        v-model="chexboxAllDayTasks"
                        style="margin-right: 5px;"
                      />
                      Select All
                    </label>
                    <hr class="mt-1 mb-1" style="border-color: #ccc;" />
                    <ul
                      v-if="filteredTasks.length > 0"
                      style="list-style: none; padding-left: 0;"
                    >
                      <li
                        v-for="(task, index) in filteredTasks"
                        :key="day + index"
                        style="margin-bottom: 5px;"
                      >
                        <label class="checkbox" style="margin-left: 10px;">
                          <input
                            type="checkbox"
                            :value="task.id"
                            v-model="selectedDayTasks"
                            style="margin-right: 5px;"
                          />
                          {{ task.title }}
                        </label>
                      </li>
                    </ul>
                    <span v-else style="margin-left: 10px;"
                      >No task found.</span
                    >
                  </div>
                  <button
                    @click="applyFilter(day)"
                    class="btn btn-primary"
                    style="width: 100%; margin-top: 10px;"
                  >
                    Apply Filter
                  </button>
                </b-popover>
              </th>
            </tr>
          </thead>

          <tbody class="text-center">
            <template
              v-for="(scheduleUserData,
              scheduleUserDataIndex) in filterredScheduleDatas"
            >
              <!-- Sol tarafta kullanıcı verileri -->
              <tr
                :key="'row_pm_' + (scheduleUserDataIndex + 1)"
                class="weekTableTr"
              >
                <td
                  :rowspan="getRowspan(scheduleUserData.scheduleData)"
                  v-if="hideMode && !showHidden"
                >
                  <b-button
                    class="btn btn-sm"
                    variant="danger"
                    @click="hideUser(scheduleUserData)"
                    >Hide</b-button
                  >
                </td>
                <td
                  :rowspan="getRowspan(scheduleUserData.scheduleData)"
                  v-if="hideMode && showHidden"
                >
                  <b-button
                    class="btn btn-sm"
                    variant="success"
                    @click="unhideUser(scheduleUserData)"
                    >Unhide</b-button
                  >
                </td>

                <td :rowspan="getRowspan(scheduleUserData.scheduleData)">
                  {{ scheduleUserData.userData.department.title }}
                </td>
                <td :rowspan="getRowspan(scheduleUserData.scheduleData)">
                  {{ scheduleUserData.userData.site.title }}
                  <span v-if="scheduleUserData.userData.multiple_sites" @click="filterMultipleSitesUser(scheduleUserData.userData.user.id)">

                  <feather-icon
                  icon="AlertCircleIcon"
                  size="15"
                  v-b-tooltip.hover title="User is assigned to multiple sites. Click to filter"
                  class="warning-button"
                  
                  />
                  </span>        
                </td>
                <td :rowspan="getRowspan(scheduleUserData.scheduleData)">
                  {{ scheduleUserData.userData.user.role }}
                </td>


                <td :rowspan="getRowspan(scheduleUserData.scheduleData)">
                  {{ scheduleUserData.userData.user.name }}
                  {{ scheduleUserData.userData.user.surname }}
                         </td>
      
                <!-- AM zaman diliminde görev varsa göster -->
                <template v-if="hasItems(scheduleUserData.scheduleData, 'am')">
                  <td>AM</td>
                 
                  <template v-for="dayName in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']">
  <template v-if="scheduleUserData.scheduleData.am[dayName].length > 0">
    <td :key="'am_' + scheduleUserDataIndex + '_' + dayName">
      <div>
        <template v-for="(amDayData, amDayKey) in scheduleUserData.scheduleData.am[dayName]">

        <draggable
          v-model="scheduleUserData.scheduleData.am[dayName]"
          :group="{ name: 'tasks', pull: 'clone', put: true }"
          :clone="cloneRota"
          @end="(event) => onDragEnd(event, scheduleUserData)"
          :userDataSchedule="scheduleUserData.userData"
            :dayName="dayName"
            :info="scheduleUserData.scheduleData.am[dayName][amDayKey]"
            :dragPeriod="scheduleUserData.scheduleData.am[dayName]"
            period="AM"
              :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"


        >
            <popover
              @changeTaskCompleted="childSelectWeek"
              :key="'am_' + scheduleUserDataIndex + '_' + dayName + '_' + amDayKey"
              :day="amDayData"
              @formShow="handleFormShow"
              :day-index="amDayKey"
              :user-role="userRole"
              :department-id="scheduleUserData.userData.department.id"
              :tasks="tasks"
              :targetId="amDayData.id.toString()"
              :userId="amDayData.user_id"
              v-model="scheduleUserData.scheduleData.am[dayName][amDayKey]"
              class="mb-25 mt-25"
            />
          </draggable>

          </template>
      </div>
    </td>
  </template>
  <template v-else>
    <td :key="'am_' + scheduleUserDataIndex + '_' + dayName">
      <div>
        <draggable
          :list="scheduleUserData.scheduleData.am[dayName]"
          :group="{ name: 'tasks',pull:false, put: true }"
          :dayName="dayName"
          :scheduleUserDataIndex="scheduleUserDataIndex"
          :info="scheduleUserData.scheduleData.am[dayName]"
          :dragPeriod="scheduleUserData.scheduleData.am[dayName]"
          :userDataSchedule="scheduleUserData.userData"
          period="AM"
            :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"




        >
          <ContextMenu
            @changeTaskCompleted="childSelectWeek"
            @formShow="handleFormShow"
            :user-role="userRole"
            :department-id="scheduleUserData.userData.department.id"
            :tasks="tasks"
            :targetId="'button_' + scheduleUserDataIndex + '_' + dayName"
            :userId="scheduleUserData.userData.id"
            :userDataSchedule="scheduleUserData.userData"
            :dayName="dayName"
            :period="'AM'"
            class="context-menu-target mb-25 mt-25"
          />
        </draggable>
      </div>
    </td>
  </template>
</template>
                </template>

                <!-- PM zaman diliminde görev varsa göster, sadece AM yoksa -->
                <template
                  v-if="
                    !hasItems(scheduleUserData.scheduleData, 'am') &&
                      hasItems(scheduleUserData.scheduleData, 'pm')
                  "
                >
                  <td>PM</td>
                  <template
                    v-for="dayName in [
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                    ]"
                  >
                    <template
                      v-if="
                        scheduleUserData.scheduleData.pm[dayName].length > 0
                      "
                    >
                      <td :key="'pm_' + scheduleUserDataIndex + '_' + dayName">
                        <div>
                          <template  v-for="(pmDayData, pmDayKey) in scheduleUserData
                              .scheduleData.pm[dayName]">

                          <draggable
          v-model="scheduleUserData.scheduleData.pm[dayName]"
          :group="{ name: 'tasks', pull: 'clone', put: true }"
          :clone="cloneRota"
          @end="(event) => onDragEnd(event, scheduleUserData)"
          :userDataSchedule="scheduleUserData.userData"
            :dayName="dayName"
            :info="scheduleUserData.scheduleData.pm[dayName][pmDayKey]"
            :dragPeriod="scheduleUserData.scheduleData.pm[dayName]"
            period="PM"
              :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"

            >

                          <popover
                            @changeTaskCompleted="childSelectWeek"
                           
                            :key="
                              'pm_' +
                                scheduleUserDataIndex +
                                '_' +
                                dayName +
                                '_' +
                                pmDayKey
                            "
                            :day="pmDayData"
                            @formShow="handleFormShow"
                            :day-index="pmDayKey"
                            :user-role="userRole"
                            :department-id="
                              scheduleUserData.userData.department.id
                            "
                            :tasks="tasks"
                            :targetId="pmDayData.id.toString()"
                            :userId="pmDayData.user_id"
                            v-model="
                              scheduleUserData.scheduleData.pm[dayName][
                                pmDayKey
                              ]
                            "
                            class="mb-25 mt-25"
                          />
                        </draggable>

</template>
                        </div>
                      </td>
                    </template>
                    <template v-else>
                      <td :key="'pm_' + scheduleUserDataIndex + '_' + dayName">
                        <div>
        <draggable
          :list="scheduleUserData.scheduleData.pm[dayName]"
          :group="{ name: 'tasks',pull:false, put: true }"
          :dayName="dayName"
          :scheduleUserDataIndex="scheduleUserDataIndex"
          :info="scheduleUserData.scheduleData.pm[dayName]"
          :dragPeriod="scheduleUserData.scheduleData.pm[dayName]"
          :userDataSchedule="scheduleUserData.userData"
          period="PM"
            :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"



        >

                        <ContextMenu
                          @changeTaskCompleted="childSelectWeek"
                          @formShow="handleFormShow"
                          :user-role="userRole"
                          :department-id="
                            scheduleUserData.userData.department.id
                          "
                          :tasks="tasks"
                          :targetId="
                            'button_' + scheduleUserDataIndex + '_' + dayName
                          "
                          :userId="scheduleUserData.userData.id"
                          :userDataSchedule="scheduleUserData.userData"
                          :dayName="dayName"
                          class="mb-25 mt-25"
                          :period="'PM'"
                        />
                      </draggable>
                    </div>
                      </td>
                    </template>
                  </template>
                </template>
              </tr>

              <!-- PM zaman diliminde görev varsa, AM görevleri de varsa -->
              <tr
                v-if="
                  hasItems(scheduleUserData.scheduleData, 'am') &&
                    hasItems(scheduleUserData.scheduleData, 'pm')
                "
                :key="'row_am_pm_' + (scheduleUserDataIndex + 1)"
              >
                <td>PM</td>
                <template
                  v-for="dayName in [
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                  ]"
                >
                  <template
                    v-if="scheduleUserData.scheduleData.pm[dayName].length > 0"
                  >
                    <td :key="'pm_' + scheduleUserDataIndex + '_' + dayName">
                      <div>
                        <template v-for="(pmDayData, pmDayKey) in scheduleUserData.scheduleData.pm[dayName]">

<draggable
  v-model="scheduleUserData.scheduleData.pm[dayName]"
  :group="{ name: 'tasks', pull: 'clone', put: true }"
  :clone="cloneRota"
  @end="(event) => onDragEnd(event, scheduleUserData)"
  :userDataSchedule="scheduleUserData.userData"
    :dayName="dayName"
    :info="scheduleUserData.scheduleData.pm[dayName][pmDayKey]"
    :dragPeriod="scheduleUserData.scheduleData.pm[dayName]"
    period="PM"
      :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"


>

                        <popover
                          @changeTaskCompleted="childSelectWeek"
                          
                        
                          :key="
                            'pm_' +
                              scheduleUserDataIndex +
                              '_' +
                              dayName +
                              '_' +
                              pmDayKey
                          "
                          :day="pmDayData"
                          @formShow="handleFormShow"
                          :day-index="pmDayKey"
                          :user-role="userRole"
                          :department-id="
                            scheduleUserData.userData.department.id
                          "
                          :tasks="tasks"
                          :targetId="pmDayData.id.toString()"
                          :userId="pmDayData.user_id"
                          v-model="
                            scheduleUserData.scheduleData.pm[dayName][pmDayKey]
                          "
                          class="mb-25 mt-25"
                        />
                      </draggable>

</template>
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td :key="'pm_' + scheduleUserDataIndex + '_' + dayName">
                      <div>
        <draggable
          :list="scheduleUserData.scheduleData.pm[dayName]"
          :group="{ name: 'tasks', pull:false, put: true }"
          :dayName="dayName"
          :scheduleUserDataIndex="scheduleUserDataIndex"
          :info="scheduleUserData.scheduleData.pm[dayName]"
          :dragPeriod="scheduleUserData.scheduleData.pm[dayName]"
          :userDataSchedule="scheduleUserData.userData"
          period="PM"
            :disabled="!$Can('change_task_for_all') && (! $Can('change_task_for_department') || !userData.departmentIds.includes(scheduleUserData.userData.department_id))"




        >

                      <ContextMenu
                        @changeTaskCompleted="childSelectWeek"
                        @formShow="handleFormShow"
                        :user-role="userRole"
                        :department-id="scheduleUserData.userData.department.id"
                        :tasks="tasks"
                        :targetId="
                          'button_' + scheduleUserDataIndex + '_' + dayName
                        "
                        :userId="scheduleUserData.userData.id"
                        :userDataSchedule="scheduleUserData.userData"
                        :dayName="dayName"
                        :period="'PM'"
                        class="mb-25 mt-25"
                      />
                    </draggable>
                  </div>
                    </td>
                  </template>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </span>

    <b-modal
      id="duplicate-project"
      size="lg"
      title="Weeks to be Updated"
      centered
      ok-title="Duplicate Week"
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      @ok="duplicateWeek"
      no-stacking
      @close="closeDuplicateWeekModal()"
    >
      <b-row class="bg-light-secondary mb-1">
        <b-col
          cols="6"
          style="padding:1%;"
          class="d-flex justify-content-start align-items-center"
        >
          <span class="mr-1" style="white-space: nowrap;">Copy source:</span>
          <!-- <b-button variant="primary" class="ml-2 p-1 m-0"><strong>Week: </strong> {{ selectedWeek }} - <strong>Year: </strong> {{ selectedYear }} </b-button> -->
          <treeselect
            :multiple="true"
            :options="dropdownWeeks"
            placeholder="Select Source Week(s)"
            class="p-1 source-copy-weeks"
            v-model="selectedSourceCopyWeeks"
            @input="selectSourceCopyWeeks(selectedSourceCopyWeeks)"
            :clearable="false"
            :limit="3"
            :limitText="(count) => `and ${count} week(s)`"
            :open-on-click="true"
            :searchable="true"
            style="width: 100%;max-width: 400px;"
          >
            <div slot="value-label" slot-scope="{ node }">
              {{ node.raw.customLabel }}
            </div>
          </treeselect>
        </b-col>

        <b-col cols="6" class="d-flex justify-content-end align-items-center">
          <span>Target year</span>
          <b-dropdown
            v-model="selectedYearDuplicate"
            variant="secondary"
            class="ml-1"
          >
            <template #button-content>
              {{ selectedYearDuplicate }}
            </template>
            <b-dropdown-item
              v-for="year in availableYears"
              :key="year.id"
              @click="selectDuplicateYear(year)"
            >
              {{ year.label }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-start align-items-center p-1"
        >
          <treeselect
            ref="duplicateDepartments"
            v-model="selectedDuplicateSites"
            value-consists-of="LEAF_PRIORITY"
            :limit="3"
            :multiple="true"
            :options="duplicateOptions"
          />
        </b-col>

        <b-col cols="4" class="d-flex justify-content-start align-items-center">
          <b-form-checkbox v-model="amChecked">AM</b-form-checkbox>
          <b-form-checkbox v-model="pmChecked" class="ml-2">PM</b-form-checkbox>
        </b-col>

        <b-col
          cols="8"
          class="d-flex justify-content-end align-items-center p-1"
        >
          <b-form-checkbox v-model="daysChecked.monday">Monday</b-form-checkbox>
          <b-form-checkbox v-model="daysChecked.tuesday" class="ml-2"
            >Tuesday</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.wednesday" class="ml-2"
            >Wednesday</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.thursday" class="ml-2"
            >Thursday</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.friday" class="ml-2"
            >Friday</b-form-checkbox
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-wrap justify-content-center">
            <b-button
              :variant="
                duplicateWeeks.includes(week.week_number)
                  ? 'success'
                  : 'secondary'
              "
              class="mr-25 p-1 m-25"
              v-for="week in availableDuplicateWeeks"
              :key="week.week_number"
              @click="addDuplicateWeek(week.week_number)"
            >
              <span v-b-tooltip.hover :title="formattedDate(week.start_date)">
                {{ week.week_number }}</span
              >
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2 bg-light-secondary p-1">
        <b-col cols="12" class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('all')"
            class="mr-2 btn btn"
          >
            Select All
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('past')"
            class="mr-2 btn btn"
          >
            Select Past
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('future')"
            class="mr-2 btn btn"
          >
            Select Future
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('unselect')"
            class="mr-2 btn btn"
          >
            Clear Selection
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2 text-center">
        <b-col cols="12">
          <p
            v-if="
              amChecked ||
                pmChecked ||
                Object.values(daysChecked).includes(true) ||
                selectedDuplicateSites
            "
          >
            {{ getSelectedOptionsMessage }}
          </p>
          <p v-else>
            All records from Week [{{ selectedSourceCopyWeeks }}] of [{{
              selectedYear
            }}] will be copied to the selected weeks
            {{
              duplicateWeeks.length > 0 ? duplicateWeeks : "-No Select Week-"
            }}
            of [{{ selectedYearDuplicate }}].
          </p>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="create-routine"
      size="lg"
      title="New Routine"
      no-close-on-esc
      no-close-on-backdrop
      centered
      ok-title="Create Routine"
      ok-only
      @ok="handleRoutineOk"
      no-stacking
      @close="
        (routineTask = null),
          (routineStartDate = null),
          (routineEndDate = null),
          (routineMonday = true),
          (routineTuesday = true),
          (routineWednesday = true),
          (routineThursday = true),
          (routineFriday = true)
      "
    >
      <b-form ref="routineRules">


        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              :state="nameState"
              invalid-feedback="Task is required"
            >
              <label>Routine Task</label>
              <treeselect
                v-model="routineTask"
                :multiple="false"
                :options="mappedTasks"
                :clearable="false"
                style="width: 100%;"
              >
                <template #option="{ node }">{{ node.title }}</template>
              </treeselect>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <label>Departments</label>
            <b-form-group
              :state="departmentState"
              invalid-feedback="Department is required"
            >
              <treeselect
                ref="routineDepartments"
                v-model="selectedRoutineSites"
                value-consists-of="LEAF_PRIORITY"
                :limit="3"
                :multiple="true"
                :options="routineOptions"
              />
            </b-form-group>
          </b-col>


        </b-row>


        <b-row>

          <b-col cols="12" lg="6">
            <label>Roles</label>
            <b-form-group
              :state="routineRoleState"
              invalid-feedback="Roles is required"
            >
              <treeselect
                ref="routineRoles"
                v-model="routineSelectedRoles"
                value-consists-of="LEAF_PRIORITY"
                :limit="3"
                :multiple="true"
                :options="roleOptions"
              />
            </b-form-group>
          </b-col>
          
        </b-row>

        <b-row>
          <b-col cols="6">
            <label>Routine Period</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              :state="startDateState"
              invalid-feedback="Commencement Date is required"
            >
              <flat-pickr
                placeholder="Commencement Date"
                v-model="routineStartDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :state="endDateState"
              invalid-feedback="End date is required"
            >
              <flat-pickr
                placeholder="End Date"
                v-model="routineEndDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
          >
            <small
              >Please choose the days when the routine will occur during the
              selected period.</small
            >
          </b-col>

          <b-col
            cols="4"
            class="d-flex justify-content-start p-1 align-items-center"
          >
            <b-form-checkbox v-model="amChecked">AM</b-form-checkbox>
            <b-form-checkbox v-model="pmChecked" class="ml-2"
              >PM</b-form-checkbox
            >
          </b-col>
          <b-col
            cols="8"
            class="d-flex justify-content-end mt-1 p-1 align-items-center"
          >
            <b-form-checkbox v-model="routineMonday" class="mr-2"
              >Monday</b-form-checkbox
            >
            <b-form-checkbox v-model="routineTuesday" class="mr-2"
              >Tuesday</b-form-checkbox
            >
            <b-form-checkbox v-model="routineWednesday" class="mr-2"
              >Wednesday</b-form-checkbox
            >
            <b-form-checkbox v-model="routineThursday" class="mr-2"
              >Thursday</b-form-checkbox
            >
            <b-form-checkbox v-model="routineFriday" class="mr-2"
              >Friday</b-form-checkbox
            >
          </b-col>
        </b-row>

        <b-row class="text-center">
          <b-col cols="3" class="mt-1" v-show="amChecked">
            <p>AM Start Time</p>
            <flat-pickr
              v-model="amStartTime"
              class="form-control"
              :config="startAmPickerConfig"
            />
          </b-col>

          <b-col cols="3" class="mt-1" v-show="amChecked">
            <p>AM End Time</p>
            <flat-pickr
              v-model="amEndTime"
              class="form-control"
              :config="endAmPickerConfig"
            />
          </b-col>

          <b-col cols="3" class="mt-1" v-show="pmChecked">
            <p>PM Start Time</p>
            <flat-pickr
              v-model="pmStartTime"
              class="form-control"
              :config="startPmPickerConfig"
            />
          </b-col>

          <b-col cols="3" class="mt-1" v-show="pmChecked">
            <p>PM End Time</p>
            <flat-pickr
              v-model="pmEndTime"
              class="form-control"
              :config="endPmPickerConfig"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <small v-if="routineStartDate && routineEndDate">
              Routine '{{ getRoutineTitle(routineTask) }}' will take place on
              {{ getDays() }} between {{ routineStartDate }} and
              {{ routineEndDate }} {{ getRoutineSiteNames }}</small
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="rota-builder"
      size="lg"
      title="Random Rota"
      no-close-on-esc
      no-close-on-backdrop
      centered
      ok-title="Generate Rota"
      ok-only
      @ok="handleBuilderOk"
      no-stacking
      @close="
        (builderTask = null),
          (builderStartDate = null),
          (builderEndDate = null),
          (builderUser = null),
          (selectedBuilderSites = []),
          (builderSite = null)
      "
    >
        <b-tabs horizontal fill v-model="activeTab">
          <b-tab title="User" :icon="'fas fa-user'">
            <b-form ref="rotaUserRules">

            <b-row>
              <b-col cols="12" lg="6">
            <b-form-group
              :state="builderStaffState"
              invalid-feedback="Staff is required"
            >
              <label>Staff</label>
              
              <treeselect
                v-model="builderUser"
                :multiple="false"
                :options="managedMappedUsers"
                :clearable="false"
                style="width: 100%;"
              >
                <template #option="{ node }">{{ node.title }}</template>
              </treeselect>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group
              :state="builderTaskState"
              invalid-feedback="Task is required"
            >
              <label>Task</label>
              <treeselect
                v-model="builderTask"
                :multiple="false"
                :options="mappedTasks"
                :clearable="false"
                style="width: 100%;"
              >
                <template #option="{ node }">{{ node.title }}</template>
              </treeselect>
            </b-form-group>
          </b-col>

          
          <b-col cols="12" lg="6">
            <label>Departments</label>
            <b-form-group
              :state="builderDepartmentState"
              invalid-feedback="Department is required"
            >
              <treeselect
              :disabled="!builderUser"
                ref="routineDepartments"
                v-model="selectedBuilderSites"
                value-consists-of="LEAF_PRIORITY"
                :limit="3"
                :multiple="true"
                :options="filteredBuilderDepartments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <label>Date</label>

            <b-form-group
              :state="builderFirstDateState"
              invalid-feedback="Date is required"
            >
              <flat-pickr
                placeholder="Date"
                v-model="builderStartDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">


<label class="mr-2">Start Time:</label>
<b-form-group>
  <flat-pickr v-model="builderStartTime" class="form-control mr-2" :config="startPickerConfig" />

</b-form-group>

</b-col>

<b-col cols="3">


<label class="mr-2">End Time:</label>
<b-form-group>

<flat-pickr v-model="builderEndTime" class="form-control" :config="endPickerConfig" />

</b-form-group>
</b-col>


          <b-col cols="6" class="text-center">
          <b-form-radio-group v-model="builderPeriod" class="mt-2">
            <b-form-radio value="AM">AM</b-form-radio>
            <b-form-radio value="PM">PM</b-form-radio>
          </b-form-radio-group>
      
        </b-col>
  

        </b-row>

       
    

      </b-form>

          </b-tab>
          <b-tab title="General" :icon="'fas fa-calendar'">
            <b-form ref="rotaGeneralRules">

            <b-row>
  
          <b-col cols="12" lg="6">
            <label>Departments</label>
            <b-form-group
              :state="builderDepartmentState"
              invalid-feedback="Department is required"
            >
              <treeselect
                ref="routineDepartments"
                v-model="selectedBuilderSites"
                value-consists-of="LEAF_PRIORITY"
                :limit="3"
                :multiple="true"
                :options="routineOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <label>Roles</label>
            <b-form-group
   
            >
              <treeselect
                ref="builderRoles"
                v-model="builderSelectedRoles"
                value-consists-of="LEAF_PRIORITY"
                :limit="3"
                :multiple="true"
                :options="roleOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <label>Build Period</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              :state="builderFirstDateState"
              invalid-feedback="Date is required"
            >
              <flat-pickr
                placeholder="Date"
                v-model="builderStartDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :state="builderSecondDateState"
              invalid-feedback="End date is required"
            >
              <flat-pickr
                placeholder="End Date"
                v-model="builderEndDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
     
              <label>Task</label>
              <treeselect
                v-model="builderTask"
                :multiple="false"
                :options="mappedTasks"
                :clearable="true"
                style="width: 100%;"
              >
                <template #option="{ node }">{{ node.title }}</template>
              </treeselect>
          </b-col>
   

        

          <b-col cols="6" class="d-flex justify-content-start p-1 align-items-center">
            <b-form-checkbox v-model="builderAmChecked">AM</b-form-checkbox>
            <b-form-checkbox v-model="builderPmChecked" class="ml-2"
              >PM</b-form-checkbox>
      
        </b-col>
        </b-row>
      </b-form>

          </b-tab>
        </b-tabs>
      
    

        

      
    </b-modal>


  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BModal,
  VBPopover,
} from "bootstrap-vue";
import moment from "moment";
import toolStoreModule from "./toolStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Popover from "./Popover.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import flatPickr from "vue-flatpickr-component";
import AppColorPaletteVue from "@/@core/layouts/components/AppColorPalette.vue";
import ContextMenu from "./ContextMenu.vue";
import draggable from 'vuedraggable';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    ToastificationContent,
    Popover,
    Treeselect,
    flatPickr,
    AppColorPaletteVue,
    ContextMenu,
    draggable
  },

  directives: {
    "b-popover": VBPopover,
  },

  setup() {
    const TOOl_APP_STORE_MODULE_NAME = "dashboard";
    // Register module
    if (!store.hasModule(TOOl_APP_STORE_MODULE_NAME))
      store.registerModule(TOOl_APP_STORE_MODULE_NAME, toolStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOOl_APP_STORE_MODULE_NAME))
        store.unregisterModule(TOOl_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const now = moment(new Date());

    return {
      hideMode: false,
      showHidden: false,
      startAmPickerConfig: this.getPickerConfig("00:00", "12:00"),
      endAmPickerConfig: this.getPickerConfig("00:00", "12:00"),
      startPmPickerConfig: this.getPickerConfig("12:00", "23:59"),
      endPmPickerConfig: this.getPickerConfig("12:00", "23:59"),
      amStartTime: "09:00",
      amEndTime: "13:00",
      pmStartTime: "14:00",
      pmEndTime: "16:00",
      filterApplied: false,
      days: ["monday", "tuesday", "wednesday", "thursday", "friday"],
      selectedYearDuplicate: new Date().getFullYear(),
      dateNow: now.format("DD/MM/YYYY"),
      userData: JSON.parse(localStorage.getItem("userData")),
      userPermms: JSON.parse(localStorage.getItem("userPerms")),
      availableDuplicateWeeks: [],
      selectedWeek: now.isoWeek(),
      duplicateWeeks: [],
      selectedSourceCopyWeeks: [],
      formShow: false,
      weeks: [],
      dropdownWeeks: [],
      scheduleData: [],
      filterredScheduleDatas: [],
      weeksDates: [],
      tasks: [],
      mappedTasks: [],
      userRole: null,
      departmentId: null,

      departmentsData: [],
      sitesData: [],
      staffsData: [],
      dayTasksData: [],
      rolesData: [],
      emptyArray:[],

      selectedDepartments: [],
      selectedSites: [],
      selectedStaffs: [],
      selectedDayTasks: [],
      selectedTimes: [],
      selectedRoles: [],

      roleOptions : [],
      builderSelectedRoles: [],
      routineSelectedRoles: [],


      siteFilter: "",
      departmentFilter: "",
      staffFilter: "",
      dayTaskFilters: "",
      roleFilter: "",

      filterDay: null,

      chexboxAllDepartment: false,
      chexboxAllSite: false,
      chexboxAllStaff: false,
      chexboxAllDayTasks: false,
      checkboxAllTime: false,
      chexboxAllRole: false,

      amChecked: true,
      pmChecked: true,

      daysChecked: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
      },

      builderTask : null,
      builderStartDate : null,
      builderEndDate : null,
      builderUser : null,
      builderSite : null,
      managedMappedUsers:[],
      builderOptions:[],
      selectedBuilderSites:[],
      startPickerConfig: this.getPickerConfig('09:00', '14:00'),
      endPickerConfig: this.getPickerConfig('13:00', '16:00'),
      builderPeriod:'AM',

      builderAmChecked: true,
      builderPmChecked: true,

      builderStartTime: '09:00',
      builderEndTime:'13:00',

      activeTab:0,


      selectedYear: new Date().getFullYear(),
      availableYears: [
        { id: 2024, label: 2024 },
        { id: 2025, label: 2025 },
        { id: 2026, label: 2026 },
        { id: 2027, label: 2027 },
        { id: 2028, label: 2028 },
        { id: 2029, label: 2029 },
        { id: 2030, label: 2030 },
      ],
      windowHeight: window.innerHeight - 200,
      fields: [
        {
          key: "title",
          label: "Department",
          sortable: false,
          class: "text-left",
          thStyle: "width:80%",
        },
        {
          key: "actions",
          sortable: false,
          class: "text-right",
          thStyle: "width:20%",
        },
      ],

      routineMonday: true,
      routineTuesday: true,
      routineWednesday: true,
      routineThursday: true,
      routineFriday: true,

      routineStartDate: null,
      routineEndDate: null,
      routineOptions: [],
      duplicateOptions:[],
      selectedRoutineSites: [],
      selectedDuplicateSites: [],
      routineTask: null,
      dragged : null,
      draggingInfo:null,
      routineColor: {
        textColor: "",
        bgColor: "",
      },
      datePickerConfig: {
        dateFormat: "d/m/Y",
        locale: {
          firstDayOfWeek: 1, // Monday
        },
        onChange: this.updateRoutineDates,
      },
      isMountedNow: true,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchWeeks();
      window.addEventListener("resize", this.getWindowHeight(200));
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight(200));
  },

  computed: {



    filteredBuilderDepartments(){
      if(this.builderUser){
        const siteIds = this.staffsData.filter((e)=>{
       if(e.id == this.builderUser){
        return e;
       } 
      })[0].site_id_array;

      


      // Filter the departmentOptions based on siteIds
      const departmentOptions = this.builderOptions[0]?.children?.map(department => {
        const filteredSites = department.children.filter(site => siteIds.includes(site.id));
        if (filteredSites.length > 0) {
          return {
            ...department,
            children: filteredSites
          };
        }
        return null;
      }).filter(department => department !== null);



      return departmentOptions;
      }
      else{
        return this.builderOptions;
      }
     
    
     

    },


    getSelectedOptionsMessage() {
      let message = `Records from week(s) [${
        this.selectedSourceCopyWeeks.length > 0
          ? this.selectedSourceCopyWeeks
          : "-No selected source week-"
      }] of [${this.selectedYear}] will be copied to the selected week(s) [${
        this.duplicateWeeks.length > 0
          ? this.duplicateWeeks
          : "-No Selected target Week-"
      }] of [${this.selectedYearDuplicate}]`;

      if (this.amChecked && this.pmChecked) {
        message += " for [AM,PM]";
      } else {
        if (this.amChecked) message += " for [AM]";
        if (this.pmChecked) message += " for [PM]";
      }

      const days = [];
      if (this.daysChecked.monday) days.push("Monday");
      if (this.daysChecked.tuesday) days.push("Tuesday");
      if (this.daysChecked.wednesday) days.push("Wednesday");
      if (this.daysChecked.thursday) days.push("Thursday");
      if (this.daysChecked.friday) days.push("Friday");

      if (days.length > 0) {
        message += " on [" + days.join(", ") + "]";
      }

      const selectedSites = this.getSelectedSites();
      if (selectedSites) {
        message += ` for the sites [${selectedSites}]`;
      }

      return message + ".";
    },

    getRoutineSiteNames() {
      var message = "";
      const selectedSites = this.getSelectedRoutineSites();
      if (selectedSites) {
        message += ` for the sites [${selectedSites}]`;
      }

      return message + ".";
    },

    displayedWeeks() {
      const selectedWeekIndex = this.weeks.findIndex(
        (week) => week.week_number === this.selectedWeek
      );
      const start = Math.max(0, selectedWeekIndex - 2);
      const end = Math.min(this.weeks.length, start + 5);
      return this.weeks.slice(start, end);
    },

    getWeekDate() {
      const week = this.weeks.find(
        (week) => week.week_number === this.selectedWeek
      );
      return week ? moment(week.start_date).format("DD/MM/YYYY") : "";
    },

    filteredDepartments() {
      if (!this.departmentFilter) return this.departmentsData;
      return this.departmentsData.filter((department) =>
        department.title
          .toLowerCase()
          .includes(this.departmentFilter.toLowerCase())
      );
    },

    filteredSites() {
      return this.sitesData.filter((site) => {
        // Check if the site title matches the site filter
        const matchesTitle = site.title
          .toLowerCase()
          .includes(this.siteFilter.toLowerCase());

        // If SelectedDepartments is not empty, check if the site's department_id matches
        const matchesDepartment =
          this.selectedDepartments.length === 0 ||
          this.selectedDepartments.includes(site.department_id);

        return matchesTitle && matchesDepartment;
      });
    },

    filteredStaffs() {
      return this.staffsData.filter((staff) => {
        // Check if the full name matches the staff filter
        const fullName = `${staff.name} ${staff.surname}`.toLowerCase();
        const matchesName = fullName.includes(this.staffFilter.toLowerCase());

        // If SelectedDepartments is not empty, check if the staff's department_id matches
        const matchesDepartment =
          this.selectedDepartments.length === 0 ||
          this.selectedDepartments.includes(staff.department_id);

        // If SelectedSites is not empty, check if the staff's site_id matches
        const matchesSite =
          this.selectedSites.length === 0 ||
          this.selectedSites.includes(staff.site_id);

        return matchesName && matchesDepartment && matchesSite;
      });
    },

    filteredTasks() {
      return this.dayTasksData.filter((day) =>
        day.title.toLowerCase().includes(this.dayTaskFilters.toLowerCase())
      );
    },

    filteredRoles() {
      return this.rolesData.filter((role) =>
        role.name.toLowerCase().includes(this.roleFilter.toLowerCase())
      );
    },

    departmentState() {
      return this.selectedRoutineSites.length > 0;
    },

    nameState() {
      return this.routineTask != null && this.routineTask !== "";
    },

    startDateState() {
      return this.routineStartDate != null && this.routineStartDate !== "";
    },

    endDateState() {
      return this.routineEndDate != null && this.routineEndDate !== "";
    },

    builderDepartmentState() {
      return this.selectedBuilderSites.length>0;
    },

    routineRoleState() {
      return this.routineSelectedRoles.length>0;
    },
    
    builderRoleState() {
      return this.builderSelectedRoles.length>0;
    },

    

    builderStaffState() {
      return this.builderUser != null && this.builderUser !== "";
    },
    builderTaskState() {
      return this.builderTask != null && this.builderTask !== "";
    },

    builderFirstDateState() {
      return this.builderStartDate != null && this.builderStartDate !== "";
    },

    builderSecondDateState() {
      return this.builderEndDate != null && this.builderEndDate !== "";
    },

  },

  watch: {
    selectedDepartments(newSelectedDepartments) {
      this.chexboxAllDepartment =
        newSelectedDepartments.length === this.departmentsData.length;
      localStorage.setItem(
        "selectedDepartments",
        JSON.stringify(this.selectedDepartments)
      );
    },
    activeTab(){

      this.builderTask = null;
      this.builderStartDate = null;
      this.builderEndDate = null;
      this.builderUser = null;

      this.selectedBuilderSites=[];

    },

    builderPeriod(newPeriod) {
        this.updatePickerConfig(newPeriod);
      },


    selectedSites(newSelectedSites) {
      this.chexboxAllSite = newSelectedSites.length === this.sitesData.length;
    },

    // selectedRoutineSites(newSelectedRoutineSites) {
    //   console.log(newSelectedRoutineSites);
    // },

    selectedStaffs(newSelectedStaffs) {
      this.chexboxAllStaff =
        newSelectedStaffs.length === this.staffsData.length;
    },

    selectedRoles(newSelectedRoles) {
      this.chexboxAllRole = newSelectedRoles.length === this.rolesData.length;
    },

    selectedDayTasks(newSelectedTasks) {
      this.chexboxAllDayTasks =
        newSelectedTasks.length === this.dayTasksData.length;
    },
    selectedTimes(newSelectedTimes) {
      this.checkboxAllTime = newSelectedTimes.length === 2;
    },
  },

  methods: {
    getPickerConfig(minTime, maxTime) {
        return {
          static: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true,

        };
      },
      updatePickerConfig(period) {
        // const minTime = period === 'AM' ? '00:00' : '12:00';
        // const maxTime = period === 'AM' ? '12:00' : '23:59';
        // this.startPickerConfig = this.getPickerConfig(minTime, maxTime);
        // this.endPickerConfig = this.getPickerConfig(minTime, maxTime);

        this.builderStartTime = period === 'AM' ? '09:00' : '14:00';
        this.builderEndTime = period === 'AM' ? '13:00' : '16:00';
      },

    log(evt) {
    console.log('Drag event:', evt);
  },
  onDragEnd(evt, scheduleUserData) {
    console.log(scheduleUserData)
    const { item, from, to } = evt;

    // Log the additional data


 

    this.formShow = true;
    this.dragged = from.__vue__.$attrs.info;

          store
          .dispatch('dashboard/dragSchedule', 

            {
          userData:  to.__vue__.$attrs.userDataSchedule,
          new_task_id: this.dragged.task.id,
          start_time: this.dragged.start_time,
          end_time: this.dragged.end_time,
          period: to.__vue__.$attrs.period,
          week:scheduleUserData.userData.week,
          year:scheduleUserData.userData.year,
          day:to.__vue__.$attrs.dayName,
          removeConflict:false,
        }


            )
            .then((res) => {
              
          if(res.data.message=='Another Site'){

          this.$swal({
            title: 'The user is assigned to another site',
            text: 'User tasks in ' + res.data.site + ' will be removed. Do you want to continue?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
            this.formShow = true;

            store
                  .dispatch('dashboard/dragSchedule', 

                    {
                  userData:  to.__vue__.$attrs.userDataSchedule,
                  new_task_id: this.dragged.task.id,
                  start_time: this.dragged.start_time,
                  end_time: this.dragged.end_time,
                  period: to.__vue__.$attrs.period,
                  week:scheduleUserData.userData.week,
                  year:scheduleUserData.userData.year,
                  day:to.__vue__.$attrs.dayName,
                  removeConflict:true,
                }
            )
              .then((res) => {

              this.showSuccessToast('✔️ Task Update Successful');
              this.formShow = false;

              res.data.schedule.forEach(element => {
                const scheduleFilteredIndex = this.scheduleData.findIndex((e) => {
                return e.userData.user_id == element.userData.user_id && element.userData.site_id == e.userData.site_id;
              });

              // Check if the item was found


              if (scheduleFilteredIndex !== -1) {
                this.$set(this.scheduleData, scheduleFilteredIndex, element);
                // this.draggingInfo.period= element;

              } else {
                this.scheduleData.push(element);
              }
              });
              

            })
            .catch((error) => {
              console.log('error ', error);
            });
          }
          else{
            res.data.schedule.forEach(element => {
                const scheduleFilteredIndex = this.scheduleData.findIndex((e) => {
                return e.userData.user_id == element.userData.user_id && element.userData.site_id == e.userData.site_id;
              });

              // Check if the item was found


              if (scheduleFilteredIndex !== -1) {
                this.$set(this.scheduleData, scheduleFilteredIndex, element);
                // this.draggingInfo.period= element;

              } else {
                this.scheduleData.push(element);
              }
              });
            this.formShow = false;
          }
          });

        }
        else{
          this.showSuccessToast('✔️ Task Update Successful');
              this.formShow = false;

              res.data.schedule.forEach(element => {
                const scheduleFilteredIndex = this.scheduleData.findIndex((e) => {
                return e.userData.user_id == element.userData.user_id && element.userData.site_id == e.userData.site_id;
              });

              // Check if the item was found


              if (scheduleFilteredIndex !== -1) {
                this.$set(this.scheduleData, scheduleFilteredIndex, element);
                // this.draggingInfo.period= element;

              } else {
                this.scheduleData.push(element);
              }
              });
        }

 
        })
        .catch((error) => {
          console.log(error)
          this.formShow=false;
        });

            


  },
  showWarningToast(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            text: message,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
      },

      showSuccessToast(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: message,
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      },


  cloneRota(element) {
    // Use the stored information
    if (this.draggingInfo) {
      return {
        ...element,
        period: this.draggingInfo.period,
        dayName: this.draggingInfo.dayName,
        userId: this.draggingInfo.userId,
      };
    }
    
    return element;
  },

    filterMultipleSitesUser(userId){
      if(!this.selectedStaffs.includes(userId)){
        this.selectedStaffs=[];
      this.selectedStaffs.push(userId);
      this.applyFilter('user')
      }

    },
    checkShowHidden() {
      if (this.showHidden == true) {
        this.showHidden = false;
        this.selectWeek(this.selectedWeek);
      }
    },

    unhideUser(scheduleUserData) {
      this.$swal({
        title: "Hide User",
        text: "Do you want to unhide the user from:",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "All Weeks",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonText: "Only This Week",
        customClass: {
          confirmButton: "mr-2",
          denyButton: "mr-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendUnhideUserRequest(true, scheduleUserData); // User chose "All Schedules"
          this.formShow = true;
        } else if (result.isDenied) {
          this.sendUnhideUserRequest(false, scheduleUserData); // User chose "Only This Week"
          this.formShow = true;
        } else {
          console.log("User cancelled the action.");
        }
      });
    },

    hideUser(scheduleUserData) {
      this.$swal({
        title: "Hide User",
        text: "Do you want to hide the user from:",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "All Weeks",
        cancelButtonText: "Cancel",
        showDenyButton: true,
        denyButtonText: "Only This Week",
        customClass: {
          confirmButton: "mr-2",
          denyButton: "mr-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendHideUserRequest(true, scheduleUserData); // User chose "All Schedules"
          this.formShow = true;
        } else if (result.isDenied) {
          this.sendHideUserRequest(false, scheduleUserData); // User chose "Only This Week"
          this.formShow = true;
        } else {
          console.log("User cancelled the action.");
        }
      });
    },

    async sendHideUserRequest(allSchedule, scheduleUserData) {
      try {
        const payload = {
          userId: scheduleUserData.userData.user_id,
          allSchedule: allSchedule,
          year: allSchedule ? undefined : scheduleUserData.userData.year,
          week: allSchedule ? undefined : scheduleUserData.userData.week,
        };

        const response = await store.dispatch(
          "dashboard/hideSchedule",
          payload
        );
        this.removeFromFilteredScheduleDatas(scheduleUserData);
        this.formShow = false;
      } catch (error) {
        // Handle error
        this.formShow = false;
        console.error(error);
      }
    },

    async sendUnhideUserRequest(allSchedule, scheduleUserData) {
      try {
        const payload = {
          userId: scheduleUserData.userData.user_id,
          allSchedule: allSchedule,
          year: allSchedule ? undefined : scheduleUserData.userData.year,
          week: allSchedule ? undefined : scheduleUserData.userData.week,
        };

        const response = await store.dispatch(
          "dashboard/unhideSchedule",
          payload
        );
        this.removeFromFilteredScheduleDatas(scheduleUserData);
        this.formShow = false;
        console.log(response.data);
      } catch (error) {
        // Handle error
        this.formShow = false;
        console.error(error);
      }
    },

    removeFromFilteredScheduleDatas(scheduleUserData) {
      const index = this.filterredScheduleDatas.findIndex(
        (e) => e === scheduleUserData
      );
      if (index > -1) {
        this.filterredScheduleDatas.splice(index, 1);
      }
    },

    getRoutineTitle(routineTask) {
      const foundTask = this.mappedTasks.find((e) => e.id == routineTask);
      return foundTask ? foundTask.label : "Default Title";
    },

    getPickerConfig(minTime, maxTime) {
      return {
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,

      };
    },

    filterOnMountedOnly() {
      if (!this.$Can("view_department_schedules")) {
        this.selectedDepartments.push(this.userData.department_id);
        this.selectedStaffs.push(this.userData.id);
        this.filterApplied = true;
      } else {
        this.selectedDepartments.push(this.userData.department_id);
        this.filterApplied = true;
      }
    },

    getSelectedSites() {
      const selectedSites = [];
      this.selectedDuplicateSites.forEach((siteId) => {
        this.routineOptions.forEach((option) => {
          const foundSite = option.children.find(
            (child) => child.id === siteId
          );
          if (foundSite) {
            selectedSites.push(foundSite.label);
          }
        });
      });

      // const department = this.routineOptions.find((option) => option.id === `${this.userData.department_id}-d`);
      // if (department && department.children) {
      //   department.children.forEach((child) => {
      //     if (!this.selectedRoutineSites.includes(child.id)) {
      //       this.selectedRoutineSites.push(child.id);
      //     }
      //     if (!this.selectedDuplicateSites.includes(child.id)) {
      //       this.selectedDuplicateSites.push(child.id);
      //     }
      //   });
      // }
      // this.selectedDepartments.push(this.userData.department_id);

      return selectedSites.join(", ");
    },

    getSelectedRoutineSites() {
      const selectedSites = [];
      this.selectedRoutineSites.forEach((siteId) => {
        this.routineOptions.forEach((option) => {
          const foundSite = option.children.find(
            (child) => child.id === siteId
          );
          if (foundSite) {
            selectedSites.push(foundSite.label);
          }
        });
      });

      // const department = this.routineOptions.find((option) => option.id === `${this.userData.department_id}-d`);
      // if (department && department.children) {
      //   department.children.forEach((child) => {
      //     if (!this.selectedRoutineSites.includes(child.id)) {
      //       this.selectedRoutineSites.push(child.id);
      //     }
      //     if (!this.selectedRoutineSites.includes(child.id)) {
      //       this.selectedRoutineSites.push(child.id);
      //     }
      //   });
      // }
      // this.selectedDepartments.push(this.userData.department_id);

      // if (!this.$Can('view_department_schedules')) {
      //   this.selectedDepartments.push(this.userData.department_id);
      //   this.selectedStaffs.push(this.userData.id);
      //   this.filterApplied = true;
      // }
      // else{
      //           this.selectedDepartments.push(this.userData.department_id);
      //           this.filterApplied = true;

      // }

      return selectedSites.join(", ");
    },

    getRowspan(scheduleData) {
      const hasAM = this.hasItems(scheduleData, "am");
      const hasPM = this.hasItems(scheduleData, "pm");
      return hasAM && hasPM ? 2 : 1;
    },

    hasItems(scheduleData, time) {
      const days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
      return days.some((day) => scheduleData[time][day].length > 0);
    },

    handleFormShow(value) {
      this.formShow = value;
    },

    getWindowHeight(val) {
      this.windowHeight = window.innerHeight - val;
    },

    getDepartmentTitle(departmentId) {
      const department = this.departmentsData.find(
        (dep) => dep.id === departmentId
      );
      return department ? department.title : "N/A";
    },
    getSiteTitle(siteId) {
      const site = this.sitesData.find((site) => site.id === siteId);
      return site ? site.title : "N/A";
    },
    getStaffName(staffId) {
      const staff = this.staffsData.find((staff) => staff.id === staffId);
      return staff ? `${staff.name} ${staff.surname}` : "N/A";
    },
    getTaskWithDay(taskId) {
      const task = this.dayTasksData.find((task) => task.id === taskId);
      const day = this.filterDay
        ? `(${this.capitalizeFirstLetter(this.filterDay)})`
        : "";
      return task ? `${task.title} ${day}` : "N/A";
    },

    getRoleName(roleId) {
      const role = this.rolesData.find((role) => role.id === roleId);
      return role ? `${role.name}` : "N/A";
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    removeDepartment(id) {
      this.selectedDepartments = this.selectedDepartments.filter(
        (departmentId) => departmentId !== id
      );
      this.checkFilterApplied();
    },
    removeSite(id) {
      this.selectedSites = this.selectedSites.filter((siteId) => siteId !== id);
      this.checkFilterApplied();
    },
    removeStaff(id) {
      this.selectedStaffs = this.selectedStaffs.filter(
        (staffId) => staffId !== id
      );
      this.checkFilterApplied();
    },
    removeTask(id) {
      this.selectedDayTasks = this.selectedDayTasks.filter(
        (taskId) => taskId !== id
      );
      this.checkFilterApplied();
    },

    removeRole(id) {
      this.selectedRoles = this.selectedRoles.filter((roleId) => roleId !== id);
      this.checkFilterApplied();
    },

    removeDayFilter() {
      this.filterDay = null;
      this.checkFilterApplied();
    },

    removeTime(time) {
      this.selectedTimes = this.selectedTimes.filter((t) => t !== time);
      this.checkFilterApplied();
    },

    checkFilterApplied() {
      this.filterApplied =
        this.selectedDepartments.length > 0 ||
        this.selectedSites.length > 0 ||
        this.selectedStaffs.length > 0 ||
        this.selectedDayTasks.length > 0 ||
        this.selectedTimes.length > 0 ||
        this.selectedRoles.length > 0;
      this.applyFilter();
    },

    clearAllFilters() {
      this.selectedDepartments = [];
      this.selectedSites = [];
      this.selectedStaffs = [];
      this.selectedDayTasks = [];
      this.selectedTimes = [];
      this.selectedRoles = [];
      this.filterDay = null;
      this.filterApplied = false;

      // if (this.$Can('view_department_schedules')) {

      //   this.selectedDepartments.push(this.userData.department_id);
      //   this.filterApplied = true;
      // } else {
      //   this.selectedDepartments.push(this.userData.department_id);
      //   this.selectedStaffs.push(this.userData.id);
      //   this.filterApplied = true;
      // }
      this.applyFilter();
    },

    applyFilter(val) {
      if (this.days.includes(val) && this.selectedDayTasks.length > 0) {
        this.filterDay = val;
      }

      if (
        (this.filterApplied =
          this.selectedDepartments.length > 0 ||
          this.selectedSites.length > 0 ||
          this.selectedStaffs.length > 0 ||
          this.selectedDayTasks.length > 0 ||
          this.selectedTimes.length > 0 ||
          this.selectedRoles.length > 0)
      ) {
        this.getWindowHeight(260);
        this.filterApplied = true;
      } else {
        this.getWindowHeight(200);
      }

      this.selectWeek(this.selectedWeek);
      this.$root.$emit("bv::hide::popover");
    },

    selectAllDepartments() {
      this.selectedDepartments = this.chexboxAllDepartment
        ? this.departmentsData.map((department) => department.id)
        : [];
    },

    selectAllSites() {
      this.selectedSites = this.chexboxAllSite
        ? this.filteredSites.map((site) => site.id)
        : [];
    },

    selectAllStaffs() {
      this.selectedStaffs = this.chexboxAllStaff
        ? this.filteredStaffs.map((staff) => staff.id)
        : [];
    },

    selectAllDayTasks(day) {
      this.selectedDayTasks = this.chexboxAllDayTasks
        ? this.dayTasksData.map((task) => task.id)
        : [];
    },

    selectAllRoles() {
      this.selectedRoles = this.chexboxAllRole
        ? this.rolesData.map((role) => role.id)
        : [];
    },

    selectAllTimes() {
      this.selectedTimes = this.checkboxAllTime ? ["AM", "PM"] : [];
    },

    async fetchWeeks() {
      this.formShow = true;
      try {
        const res = await store.dispatch("dashboard/fetchWeeks", {
          year: this.selectedYear,
        });
        this.processFetchedWeeks(res.data);
      } catch (error) {
        this.handleFetchError(error, "fetchWeeks");
      }
    },

    processFetchedWeeks(data) {
      this.weeks = data.weeks;
      this.dropdownWeeks = this.weeks.map((week) => ({
        id: week.week_number,
        label: `Week ${week.week_number} | ${this.formattedDate(
          week.start_date
        )} - ${this.formattedDate(week.end_date)}`,
        customLabel: `Week ${week.week_number} `,
      }));

      if (this.isMountedNow) {
        // this.filterOnMountedOnly();
        this.selectedDepartments = [];
        let departmentsStorage = JSON.parse(
          localStorage.getItem("selectedDepartments")
        );
        if (departmentsStorage) {
          departmentsStorage.forEach((element) => {
            this.selectedDepartments.push(element);
          });
        }

        if (this.selectedDepartments.length > 0) {
          this.filterApplied = true;
        }
      }

      this.departmentsData = data.departments || [];
      this.sitesData = data.sites || [];
      this.staffsData = data.staffs || [];
      this.dayTasksData = data.tasks || [];
      this.rolesData = data.roles || [];
      this.roleOptions = data.roleOptions;


      this.tasks = data.tasks;
      this.mappedTasks = this.tasks.map((task) => ({
        label: task.title,
        id: task.id,
      }));

      this.managedMappedUsers = data.managedStaffs.map((user) => ({
        label: user.name + ' ' + user.surname,
        id: user.id,
      }));
      // console.log(data.options);
      this.routineOptions = data.routineOptions || [];
      this.builderOptions = data.routineOptions ||[];
      this.duplicateOptions = data.duplicateOptions || [];

      // console.log(this.routineOptions);
      this.availableDuplicateWeeks = data.weeks;
      this.selectWeek(this.selectedWeek);
    },
    
    selectYear(year) {
      this.selectedWeek = 1;
      this.selectedYear = year;
      this.selectedYearDuplicate = year;
      this.fetchWeeks();
    },

    moveWeeks(offset) {
      const newSelectedWeek = this.selectedWeek + offset;
      if (newSelectedWeek > 0 && newSelectedWeek <= this.weeks.length) {
        this.selectedWeek = newSelectedWeek;
      }

      if (this.weeks.length != this.selectedWeek && this.selectedWeek != 1) {
        const selectedWeekIndex = this.weeks.findIndex(
          (week) => week.week_number === this.selectedWeek
        );
        const start = Math.max(0, selectedWeekIndex - 2);
        const end = Math.min(this.weeks.length, start + 5);

        return this.weeks.slice(start, end);
      }
    },

    async selectWeek(val) {
      if (this.weeks.length > 0) {
        this.selectedWeek = val;
        const startDate = moment(this.weeks[this.selectedWeek - 1].start_date);
        this.weeksDates = Array.from({ length: 7 }, (_, i) =>
          startDate
            .clone()
            .add(i, "days")
            .format("DD/MM/YYYY")
        );
        this.formShow = true;
        try {
          const roles = this.selectedRoles
            .map((roleId) => {
              const role = this.rolesData.find((r) => r.id === roleId);
              return role ? role.name : null;
            })
            .filter((name) => name !== null);

          const res = await store.dispatch("dashboard/getScheduleByWeek", {
            year: this.selectedYear,
            week: this.selectedWeek,
            department: this.selectedDepartments,
            site: this.selectedSites,
            staff: this.selectedStaffs,
            task: this.selectedDayTasks,
            selectedDay: this.filterDay,
            times: this.selectedTimes,
            roles: roles,
            show_hidden: this.showHidden ? 1 : 0,
          });
          this.scheduleData = res.data;
          this.filterredScheduleDatas = res.data;
          const department = this.routineOptions.find(
            (option) => option.id === `${this.userData.department_id}-d`
          );
          if (department && department.children) {
            department.children.forEach((child) => {
              if (!this.selectedRoutineSites.includes(child.id)) {
                this.selectedRoutineSites.push(child.id);
              }
              if (!this.selectedDuplicateSites.includes(child.id)) {
                this.selectedDuplicateSites.push(child.id);
              }
            });
          }

          this.formShow = false;
          this.isMountedNow = false;
        } catch (error) {
          this.handleFetchError(error, "getScheduleByWeek");
        }
      }
    },

    childSelectWeek() {
      this.formShow = true;
      this.selectWeek(this.selectedWeek);
    },

    async selectDuplicateYear(year) {
      this.duplicateWeeks = [];
      this.selectedYearDuplicate = year.label;
      if (this.selectedYearDuplicate !== new Date().getFullYear()) {
        try {
          const res = await store.dispatch("dashboard/fetchWeeks", {
            year: this.selectedYearDuplicate,
          });
          this.availableDuplicateWeeks = res.data.weeks;
        } catch (error) {
          this.handleFetchError(error, "fetchWeeks");
        }
      }
    },

    getDate(day) {
      if (this.selectedWeek && this.weeks.length > 0) {
        const date = this.weeks.find((e) => e.week_number == this.selectedWeek)
          .start_date;
        return moment(date)
          .add(day, "days")
          .format("DD/MM/YYYY");
      }
    },

    duplicate() {
      this.$nextTick(() => {
        this.$bvModal.show("duplicate-project");
      });
    },

    async downloadPDF() {
      this.formShow = true;
      try {
        const response = await store.dispatch("dashboard/downloadPDF", {
          year: this.selectedYear,
          week: this.selectedWeek,
          department: this.selectedDepartments,
          site: this.selectedSites,
          staff: this.selectedStaffs,
          task: this.selectedDayTasks,
          selectedDay: this.filterDay,
          times: this.selectedTimes,
          roles: this.selectedRoles,
        });

        if (response.status == 200) {
          const weekNumber = moment().isoWeek(); // ISO week number
          const monthName = moment().format('MMMM'); // Full month name

          // Generate the filename
          const filename = `${monthName}-Week-${weekNumber}-Schedule.pdf`;


          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          this.showToast(
            "Successful",
            "✔️ Report is created!",
            "ThumbsUpIcon",
            "success"
          );
        }
        this.formShow = false;
      } catch (error) {
        this.handleFetchError(error, "downloadPDF");
      }
    },

    addDuplicateWeek(week_number) {
      const index = this.duplicateWeeks.indexOf(week_number);
      if (index > -1) {
        this.duplicateWeeks.splice(index, 1);
      } else {
        this.duplicateWeeks.push(week_number);
      }
      this.checkExistingWeeks();
    },

    async duplicateWeek() {
      if (this.duplicateWeeks.length > 0) {
        try {
          const confirmed = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to update the selected weeks?",
            {
              size: "sm",
              title: "Confirm",
              okVariant: "primary",
              okTitle: "Yes",
              cancelTitle: "No",
              cancelVariant: "outline-secondary",
              hideHeaderClose: true,
              centered: true,
            }
          );

          if (confirmed) {
            await this.duplicateWeeksAction();
            this.showToast(
              "Successful",
              "Selected weeks have been successfully updated.",
              "success"
            );
          } else {
            this.cancelDuplicateProcess();
          }
        } catch (error) {
          this.handleDuplicateError(error);
        }
      } else {
        this.showToast(
          "No Weeks Selected",
          "You haven't selected any weeks to be updated.",
          "warning"
        );
      }
    },
    async duplicateWeeksAction() {
      // if (
      //   this.duplicateWeeks.includes(this.selectedWeek) &&
      //   this.selectedYearDuplicate == this.selectedYear
      // ) {
      //   this.duplicateWeeks.splice(
      //     this.duplicateWeeks.indexOf(this.selectedWeek),
      //     1
      //   );
      // }

      await store.dispatch("dashboard/multipleDuplicateWeeks", {
        duplicateWeeks: this.duplicateWeeks ?? [],
        selectedSourceCopyWeeks: this.selectedSourceCopyWeeks ?? [],
        currentWeek: this.selectedWeek,
        selectedSites: this.selectedDuplicateSites,
        amChecked: this.amChecked,
        pmChecked: this.pmChecked,
        daysChecked: this.daysChecked,
        year: this.selectedYear,
        selectedYearDuplicate: this.selectedYearDuplicate,
      });

      this.duplicateWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.fetchWeeks();
      this.closeDuplicateWeekModal();
    },

    closeDuplicateWeekModal() {
      this.$bvModal.hide("duplicate-project");
      this.duplicateWeeks = [];
      this.selectedSourceCopyWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.amChecked = true;
      this.pmChecked = true;
      this.daysChecked = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
      };
    },

    multipleDuplicate(option) {
      this.duplicateWeeks = [];
      const currentYear = new Date().getFullYear();
      switch (option) {
        case "all":
          this.duplicateWeeks = this.availableDuplicateWeeks.map(
            (e) => e.week_number
          );
          break;
        case "past":
          this.handlePastWeeksSelection(currentYear);
          break;
        case "future":
          this.handleFutureWeeksSelection(currentYear);
          break;
        case "unselect":
          this.duplicateWeeks = [];
          break;
        default:
          break;
      }
    },

    handlePastWeeksSelection(currentYear) {
      if (this.selectedYearDuplicate < currentYear) {
        this.duplicateWeeks = this.availableDuplicateWeeks.map(
          (e) => e.week_number
        );
      } else if (this.selectedYearDuplicate > currentYear) {
        this.duplicateWeeks = [];
      } else {
        this.duplicateWeeks = this.availableDuplicateWeeks
          .filter((e) => e.week_number < this.selectedWeek)
          .map((e) => e.week_number);
      }
    },

    handleFutureWeeksSelection(currentYear) {
      if (this.selectedYearDuplicate > currentYear) {
        this.duplicateWeeks = this.availableDuplicateWeeks.map(
          (e) => e.week_number
        );
      } else if (this.selectedYearDuplicate < currentYear) {
        this.duplicateWeeks = [];
      } else {
        this.duplicateWeeks = this.availableDuplicateWeeks
          .filter((e) => e.week_number > this.selectedWeek)
          .map((e) => e.week_number);
      }
    },

    formattedDate(startDate) {
      return moment(startDate).format("DD/MM/YYYY");
    },

    routine() {
      this.$nextTick(() => {
        this.$bvModal.show("create-routine");
      });
    },

    rotaBuilder(){
      this.$nextTick(() => {
        this.$bvModal.show("rota-builder");
      });
    },

    colorSelectedHandler(textColor, bgColor) {
      this.routineColor = {
        textColor: textColor,
        bgColor: bgColor,
      };
    },

    clearDates() {
      this.routineStartDate = null;
      this.routineEndDate = null;
    },

    updateRoutineDates() {},

    checkFormValidity() {
      const nameValid = this.$refs.routineRules.checkValidity();

      return nameValid;
    },

    checkBuilderValidity() {
      let nameValid = this.$refs.rotaUserRules.checkValidity() || this.$refs.rotaGeneralRules.checkValidity() ;
      return nameValid;



    },

    handleRoutineOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.createRoutine();
    },

    handleBuilderOk(bvModalEvent){
    // Prevent modal from closing
    bvModalEvent.preventDefault();

    this.createBuilder();
    },

    async createRoutine() {
      if (!this.checkFormValidity()) return;

      if (this.amChecked || this.pmChecked) {
        if (this.selectedRoutineSites.length > 0 && this.routineTask) {
          try {
            const confirmed = await this.$bvModal.msgBoxConfirm(
              "Are you sure you want to create a routine?",
              {
                size: "sm",
                title: "Confirm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",
                hideHeaderClose: true,
                centered: true,
              }
            );

            if (confirmed) {
              await this.createRoutineAction();
              this.showToast(
                "Successful",
                "Routine has been successfully created.",
                "success"
              );
            } else {
              this.showToast(
                "Process cancelled",
                "Routine process has been cancelled.",
                "warning"
              );
            }
          } catch (error) {
            this.handleRoutineError(error);
          }
        } else {
          this.showToast(
            "Required Fields",
            "Please fill in the required fields.",
            "warning"
          );
        }
      } else {
        this.showToast(
          "Required Fields",
          "Please choose either AM or PM, it is mandatory",
          "warning"
        );
      }
    },

    async createRoutineAction() {
      await store.dispatch("dashboard/createRoutine", {
        selectedRoutineSites: this.selectedRoutineSites,
        routineTask: this.routineTask,
        routineStartDate: this.routineStartDate,
        routineEndDate: this.routineEndDate,
        routineMonday: this.routineMonday,
        routineTuesday: this.routineTuesday,
        routineWednesday: this.routineWednesday,
        routineThursday: this.routineThursday,
        routineFriday: this.routineFriday,
        routineColor: this.routineColor,
        amChecked: this.amChecked,
        pmChecked: this.pmChecked,
        amStartTime: this.amStartTime,
        amEndTime: this.amEndTime,
        pmStartTime: this.pmStartTime,
        pmEndTime: this.pmEndTime,
        selectedRoles : this.routineSelectedRoles
      });

      this.resetRoutineForm();
      this.fetchWeeks();
    },

    resetRoutineForm() {
      this.routineTask = null;
      this.routineStartDate = null;
      this.routineEndDate = null;
      this.routineMonday = false;
      this.routineTuesday = false;
      this.routineWednesday = false;
      this.routineThursday = false;
      this.routineFriday = false;
    },

    async createBuilder() {
      if (!this.checkBuilderValidity()) return;

      let checkValid = this.activeTab == 0 ? this.builderTask && this.selectedBuilderSites.length>0 &&  this.builderStartDate && this.builderUser : this.selectedBuilderSites.length>0 && this.builderStartDate && this.builderEndDate;

      if (checkValid) {
          try {
            const confirmed = await this.$bvModal.msgBoxConfirm(
              "Any existing rota on the selected dates will be overwritten. Are you sure you want to continue?",
              {
                size: "sm",
                title: "Confirm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",
                hideHeaderClose: true,
                centered: true,
              }
            );

            if (confirmed) {
              await this.createBuilderAction();
              this.showToast(
                "Successful",
                "Rota has been successfully created.",
                "success"
              );
            } else {
              this.showToast(
                "Process cancelled",
                "Rota process has been cancelled.",
                "warning"
              );
            }
          } catch (error) {
            this.handleBuilderError(error);
          }
        }
        else {
          this.showToast(
            "Required Fields",
            "Please fill in the required fields.",
            "warning"
          );
        }
    
    },

    async createBuilderAction() {
      this.formShow = true;
      this.$bvModal.hide("rota-builder");

      await store.dispatch("dashboard/createBuilder", {
        builderTask : this.builderTask,
      builderStartDate : this.builderStartDate,
      builderEndDate : this.builderEndDate,
      builderUser : this.builderUser,
      builderSite : this.selectedBuilderSites,
      builderPeriod:this.builderPeriod,
      builderStartTime : this.builderStartTime,
      builderEndTime : this.builderEndTime,
      builderType : this.activeTab==0?'user':'general',
      selectedRoles : this.builderSelectedRoles,
      builderAmChecked:this.builderAmChecked,
      builderPmChecked:this.builderPmChecked,

      });

      this.resetBuilderForm();
      this.fetchWeeks();
    },

    resetBuilderForm() {
      this.builderTask = null;
      this.builderStartDate = null;
      this.builderEndDate = null;
      this.builderUser = null;
      this.selectedBuilderSites = [];
      this.builderSite = null;

    },

    selectSourceCopyWeeks(week_number) {
      this.checkExistingWeeks();
    },

    checkExistingWeeks() {
      if (this.selectedYearDuplicate == this.selectedYear) {
        this.selectedSourceCopyWeeks.forEach((week) => {
          const index = this.duplicateWeeks.indexOf(week);
          if (index !== -1) {
            this.duplicateWeeks.splice(index, 1);
          }
        });
      }
    },

    showToast(title, text, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant,
        },
      });
    },

    handleFetchError(error, action) {
      console.error(`Error during ${action}:`, error);
      this.formShow = false;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "An error occurred",
          text: `Please try again later or contact support. Error: ${error.message}`,
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },

    handleDuplicateError(error) {
      console.error("duplicate error: ", error);
      this.showToast(
        "Error",
        "Duplicate process could not be completed. Please try again or contact support.",
        "error"
      );
      this.formShow = false;
    },

    handleRoutineError(error) {
      console.error("routine error: ", error);
      this.showToast(
        "Error",
        "Routine process could not be completed. Please try again or contact support.",
        "error"
      );
      this.formShow = false;
    },

    cancelDuplicateProcess() {
      this.duplicateWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.showToast(
        "Process cancelled",
        "Duplicate process has been cancelled.",
        "warning"
      );
    },

    getDays() {
      if (
        this.routineMonday &&
        this.routineTuesday &&
        this.routineWednesday &&
        this.routineThursday &&
        this.routineFriday
      ) {
        return "everyday";
      }

      let listDays = "every ";
      if (this.routineMonday) listDays += "Monday, ";
      if (this.routineTuesday) listDays += "Tuesday, ";
      if (this.routineWednesday) listDays += "Wednesday, ";
      if (this.routineThursday) listDays += "Thursday, ";
      if (this.routineFriday) listDays += "Friday";

      return listDays.replace(/,\s*$/, "");
    },

    getTimeLabel(time) {
      return time === "AM" ? "AM" : "PM";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/dist/css/vue-context.css";
</style>

<style scoped>
.weekTableCard {
  background-color: white;
  padding: 0px !important;
  overflow-x: auto;
}

.weekTableCard::-webkit-scrollbar {
  width: 10px;
}

.weekTableCard::-webkit-scrollbar-track {
  background: #e1f5fe;
}

.weekTableCard::-webkit-scrollbar-thumb {
  background: #888;
}

.weekTableCard::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-badge {
  display: flex;
  align-items: center;
  background-color: #e1f5fe;
  padding: 2px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: relative;
}

.filter-badge span {
  margin-right: 5px;
  padding: 3px;
}

.close-button {
  font-size: 12px;
  padding: 0;
  width: 18px;
  height: 18px;
  margin-left: 3px;
  border: none;
  background-color: #ed2323 !important;
}

.filter-badge:hover .close-button {
  background-color: #ca0d1d !important;
}

.warning-button{
  background-color: #e27024 !important;
  border:1px solid  #e27024;
  border-radius: 50%;
  color:white;
  margin-left:5%;



}
</style>

<style>
.source-copy-weeks .vue-treeselect__control {
  width: 100%;
  max-width: 400px !important;
  width: 400px;
}


</style>
